// routes
import { useEffect } from "react";
import { StyledChart } from "./components/chart";
import Router from "./routes";
import { useDispatch } from "./redux/store";
import { getMtoConfigData } from "./redux/slices/config";
// Check our docs
// https://docs.minimals.cc/authentication/js-version

// import { AuthProvider } from './auth/Auth0Context';
// import { AuthProvider } from './auth/FirebaseContext';
// import { AuthProvider } from './auth/AwsCognitoContext';

// ----------------------------------------------------------------------

export default function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getMtoConfigData());
  }, [dispatch]);

  return (
    // <>
    //   <StyledChart />
    //   <Router />
    // </>
    <div
      style={{
        minHeight: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <h2>COMING SOON</h2>
    </div>
  );
}
