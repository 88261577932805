import { createSlice } from '@reduxjs/toolkit';
// utils
// import axios from '../../utils/axios';
import { API_ENDPOINTS } from 'src/api';
import stbServer from '../../utils/axios';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,

  receivers: [],
};

const slice = createSlice({
  name: 'remittance',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET KYCLEVELS
    getReceiversSuccess(state, action) {
      state.isLoading = false;
      state.receivers = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {} = slice.actions;

// ----------------------------------------------------------------------

export function getReceiversIndividual(country) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const params = { country, limit: 1000 };
      const response = await stbServer.get(API_ENDPOINTS.receiversIndividual, {
        params,
      });
      console.log(response);
      dispatch(slice.actions.getReceiversSuccess(response.data.results));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getReceiversBusiness() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const params = { limit: 1000 };
      const response = await stbServer.get(API_ENDPOINTS.receiversBusinessList, {
        params,
      });
      console.log(response);
      dispatch(slice.actions.getReceiversSuccess(response.data.results));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function autoSelectMto(data) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await stbServer.post(API_ENDPOINTS.selectMto, data);
      console.log(response);
      return response;
      // dispatch(slice.actions.getReceiversSuccess(response.data.results));
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
      throw new Error(error?.message ?? 'Unable to fetch rate.');
    }
  };
}
export function calculateFee(data) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await stbServer.post(API_ENDPOINTS.calculateFee, data);
      console.log(response);
      return response;
      // dispatch(slice.actions.getReceiversSuccess(response.data.results));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function createRemittance(data) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await stbServer.post(API_ENDPOINTS.remittance, data);
      console.log(response);
      return response;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error;
    }
  };
}
