import PropTypes from "prop-types";
import { useState, useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import { Card, Stack, Typography, Box, LinearProgress } from "@mui/material";
import { API_ENDPOINTS } from "src/api";
import stbServer from "src/utils/axios";
import { useSnackbar } from "notistack";
import Iconify from "src/components/iconify";
import { useLocales } from "src/locales";

export default function PaymentMethodsDetails() {
  const [accountDetails, setAccountDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const { t } = useLocales();

  async function getMasterAccountDetails() {
    try {
      setIsLoading(true);
      const response = await stbServer.get(API_ENDPOINTS.masterAccountDetails);
      setAccountDetails(response?.data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      enqueueSnackbar("Error Getting Account Details", { variant: "error" });
    }
  }

  useEffect(() => {
    getMasterAccountDetails();
  }, []);

  return (
    <Card
      sx={{
        width: 1,
        boxShadow: 0,
        color: theme.palette["warning"].darker,
        bgcolor: theme.palette["warning"].lighter,
      }}
    >
      <Stack spacing={4} sx={{ p: 3 }}>
        <Typography variant="h4">{t("accountDetails")}</Typography>
        {isLoading ? (
          <LinearProgress color="inherit" sx={{ width: 1, maxWidth: 360 }} />
        ) : (
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "repeat(2, 1fr)",
              gridGap: "20px",
            }}
          >
            <Item
              key={"name"}
              title={t("accountHolderName")}
              content={accountDetails?.name}
            />
            <Item
              key={"ac_no"}
              title={t("beneficiaryAccountNumber")}
              content={accountDetails?.account_no}
            />
            <Item
              key={"branch"}
              title={t("beneficiaryBank")}
              content={accountDetails?.branch}
            />
            <Item
              key={"swift"}
              title={t("swiftCode")}
              content={accountDetails?.swift_code}
            />
            <Item
              key={"aba_number"}
              title={t("beneficiaryAbaNumber")}
              content={accountDetails?.aba_number}
            />
            <Item
              key={"address"}
              title={t("beneficiaryAddress")}
              content={accountDetails?.address}
            />
            <Item
              key={"bank_address"}
              title={t("beneficiaryBankAddress")}
              content={"3003 Tasman Drive, Santa Clara, CA 95054, USA"}
            />
            <Item key={"currency"} title={t("currency")} content={"USD"} />
          </Box>
        )}
        <Typography>
          <b>{t("specialInstruction")}:</b> {t("pleaseAlwaysPutRemarkAs")} "{" "}
          <b>{t("remarkFormat")}</b> " {t("forQuicCredit")}.
        </Typography>
      </Stack>

      <Iconify
        icon={"hugeicons:bank"}
        sx={{
          width: 220,
          height: 220,
          opacity: 0.3,
          position: "absolute",
          right: theme.spacing(0),
          top: theme.spacing(0),
          zIndex: -1,
        }}
      />
    </Card>
  );
}

function Item({ title, content, type = "" }) {
  return (
    <>
      <Stack
        sx={{ cursor: "default" }}
        direction={"column"}
        spacing={0}
        alignItems={"flex-start"}
      >
        <Typography variant="overline">{title}</Typography>
        <Stack sx={{ display: "flex", flexDirection: "row", gap: 1 }}>
          <Typography variant="body2">{content || "--"}</Typography>
        </Stack>
      </Stack>
    </>
  );
}
