import { createSlice } from "@reduxjs/toolkit";
// utils
// import axios from '../../utils/axios';
import { API_ENDPOINTS } from "src/api";
import stbServer from "../../utils/axios";

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  remittanceAmountGraphLoading: true,
  remittanceNumberGraphLoading: true,
  countrywiseRemittanceNumberGraphLoading: true,
  remittanceReceiverGraphLoading: true,
  error: null,
  deposits: [],
  kycLimit: {},
  remittanceOverallAmounts: {},
  remittanceAmountGraph: [],
  remittanceNumberGraph: {
    complete: [],
    created: [],
    pending: [],
    reject: [],
    periods: [],
  },
  countrywiseRemittanceNumberGraph: {
    periods: [],
    country_count: [],
    country_amount: [],
  },
  remittanceReceiverGraph: [],
};

const slice = createSlice({
  name: "dash",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state, action) {
      if (action.payload) {
        state[action.payload] = true;
      } else {
        state.isLoading = true;
      }
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getDepositDetailsSuccess(state, action) {
      state.isLoading = false;
      state.deposits = action.payload;
    },

    getKycLimitDetailsSuccess(state, action) {
      state.isLoading = false;
      state.kycLimit = action.payload;
    },

    getRemittanceOverallAmountsSuccess(state, action) {
      state.isLoading = false;
      state.remittanceOverallAmounts = action.payload;
    },

    getRemittanceAmountGraphSuccess(state, action) {
      state.remittanceAmountGraphLoading = false;
      state.remittanceAmountGraph = action.payload;
    },

    getRemittanceReceiverGraphSuccess(state, action) {
      state.remittanceReceiverGraphLoading = false;
      state.remittanceReceiverGraph = action.payload;
    },

    getRemittanceNumberGraphSuccess(state, action) {
      state.remittanceNumberGraphLoading = false;
      state.remittanceNumberGraph = action.payload;
    },

    getCountrywiseRemittanceNumberGraphSuccess(state, action) {
      state.countrywiseRemittanceNumberGraphLoading = false;
      state.countrywiseRemittanceNumberGraph = action.payload;
    },

    clearDashboard(state) {
      return initialState;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {} = slice.actions;

// ----------------------------------------------------------------------

export function getDepositDetails() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await stbServer.get(API_ENDPOINTS.depositDetails);
      dispatch(slice.actions.getDepositDetailsSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getKycLimitDetails() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await stbServer.get(API_ENDPOINTS.kycLimitDetails);
      console.log(response);
      dispatch(slice.actions.getKycLimitDetailsSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getRemittanceOverallAmounts() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await stbServer.get(
        API_ENDPOINTS.remittanceOverallAmounts
      );
      console.log(response);
      dispatch(
        slice.actions.getRemittanceOverallAmountsSuccess(response.data.data)
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getRemittanceAmountGraph(group_by) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading("remittanceAmountGraphLoading"));
    try {
      const response = await stbServer.get(
        API_ENDPOINTS.remittanceAmountGraph,
        {
          params: { period: group_by },
        }
      );

      dispatch(slice.actions.getRemittanceAmountGraphSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getRemittanceNumberGraph(group_by) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading("remittanceNumberGraphLoading"));
    try {
      const response = await stbServer.get(
        API_ENDPOINTS.remittanceNumberGraph,
        {
          params: { group_by },
        }
      );
      console.log(response);
      dispatch(
        slice.actions.getRemittanceNumberGraphSuccess(response.data.data)
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getCountrywiseRemittanceNumberGraph(group_by) {
  return async (dispatch) => {
    dispatch(
      slice.actions.startLoading("countrywiseRemittanceNumberGraphLoading")
    );
    try {
      const response = await stbServer.get(
        API_ENDPOINTS.countrywiseRemittanceGraph,
        {
          params: { group_by },
        }
      );
      console.log("ascwec34c34c3c", response);
      dispatch(
        slice.actions.getCountrywiseRemittanceNumberGraphSuccess(
          response.data.data
        )
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getRemittanceReceiverGraph() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading("remittanceReceiverGraphLoading"));
    try {
      const response = await stbServer.get(
        API_ENDPOINTS.remittanceReceiverGraph
      );
      console.log(response);
      dispatch(
        slice.actions.getRemittanceReceiverGraphSuccess(response.data.data)
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function clearDashboardData() {
  return async (dispatch) => {
    dispatch(slice.actions.clearDashboard());
  };
}
