export const API_ENDPOINTS = {
  register: "/b2b/customer/user/register/",
  updateTempPassword: "/b2b/customer/user/update_temp_password/",
  newPassword: "/b2b/customer/user/new_password/",
  forgotPassword: "/b2b/customer/user/reset_password/",
  updatePassword: "/b2b/customer/user/change_password/",
  login: "/b2b/customer/user/login/",
  loginVerify: "/b2b/customer/user/login_verify/",
  supplierBusiness: "/b2b/customer/business/",
  refreshToken: "/refresh/",

  countries: "/country/",
  city: "/city/",
  province: "/province/",
  changePassword: "/mto/user/change_password/",
  senderRegister: "/customer/register/",
  profile: "/b2b/customer/user/profile/",
  deposits: "/sender/fund/deposit/",
  depositStatusOptions: "/mto/deposits/depositstatusoption/",
  timezones: "/timezones/",
  updateUser: "/b2b/customer/user/update_user/",

  // Transaction
  internalIncomingTransaction: "/b2b/transaction/transaction/",
  subAccountList: "/b2b/transaction/sub_account_list/",

  // Internal Transaction
  internalTransfers: "/b2b/transaction/internal_transfer/",

  //Master Account Details
  masterAccountDetails: "/b2b/transaction/deposit_account/",

  // Statement
  statement: "/customer/wallet/transactions/",

  // Dashboard
  depositDetails: "/customer/dashboard/deposit_details/",
  kycLimitDetails: "/customer/dashboard/kyc_details/",

  // Support
  categories: "/b2b/customer/support/category/",
  ticket: "/b2b/customer/support/user/ticket/",
  ticketReply: "/b2b/customer/support/user/reply/",

  // kyc
  kycLevels: "/kyc/kyc_level/",
  userKyc: "/kyc/user_kyc/",
  senderDocument: "/b2b/customer/document/",
  documentType: "/b2b/kyc/document_type/",

  //Config
  purpose: "/b2b/remittance/puposes/",
  coin: "/coin/",
  supportedCoins: "/supported_coin/",
  currency: "/currency/",
  balance: "/customer/wallet/balance/",

  // remittance
  receiversIndividual: "/customer/receiver/individual/",
  receiversBusiness: "/b2b/receiver/",
  receiversType: "/b2b/receiver/receiver-types/list/",
  receiverLineOfBusiness: "/b2b/receiver/line-of-business/list/",
  receiversBusinessList: "/b2b/receiver/list/",
  remittanceReceiver: "/customer/receiver/",
  selectMto: "/b2b/remittance/fees/",
  calculateFee: "/remittance/calculate_fee/",
  remittance: "/b2b/remittance/outgoing/",
  remittanceRelationship: "/b2b/remittance/relationship/list/",
  remittanceFunding: "/b2b/remittance/funding-source/list/",
  financial: "/b2b/finance_account/",
  financialAccountType: "/b2b/finance_account/account-types/list/",
  financialInstruments: "/b2b/finance_account/instruments/",

  remittanceOverallAmounts: "/b2b/customer/dashboard/total_amount/",
  remittanceAmountGraph: "/b2b/transaction/transaction-summary/",
  remittanceNumberGraph: "/customer/dashboard/remittance_no_graph/",
  remittanceReceiverGraph: "/customer/dashboard/remittance_receiver_graph/",
  countrywiseRemittanceGraph:
    "/customer/dashboard/country_based_remittance_graph/",

  senderBusiness: "/b2b/customer/business/",
  businessDocTypes: "/b2b/customer/business/kyb/doc_types/",

  // senderRegister
  // senderBusiness

  shareholder: "/b2b/customer/business/shareholder/",
  shareholderDocTypes: "/b2b/customer/business/shareholder/doc_type_list/",
  mccCodes: "/b2b/customer/business/shareholder/mcc_code_list/",
  shareholderKyc: "/b2b/customer/business/shareholder/kyc/",
  businessCategory: "/b2b/customer/business/category/",
  businessType: "/b2b/customer/business/business_type/",
  businessKyb: "/b2b/customer/business/kyb/kyb/",
  fileUpload: "/file/",
  occupations: "/b2b/customer/business/occupations/",
  kybSubmit: "/b2b/customer/business/kyb/submit/",
  file: "/file/",
  networks: "/networks/",
  paymentAddress: "/remittance/payment_address/",
  statuses: "/remittance/statuses/",

  //Contract Payable
  contract: "/b2b/contracts/",
  contractHighlights: "/b2b/contracts/highlights/counts/",

  // Payment Claims
  paymentClaim: "/b2b/transaction/unmapped_payments/",
  claimAPayment: "/b2b/unmapped_payments/",

  // Transaction Reports
  transactionReport: "/b2b/reports/transactions/",
  transactionReportHighlight: "/b2b/reports/transactions/highlights/",

  //Customer Balance
  customerBalance: "/b2b/customer/balance/",

  //KYC KYB Status history
  kycStatusHistory: "/b2b/kyc/",
  kybStatusHistory: "/b2b/customer/business/kyb/kyb/",

  // Get Internal Transfer fees details
  getInternalFeesDetails: "/b2b/transaction/fees/internal-transfer/",
};
